import ListMixin from 'qs_vuetify/src/mixins/ListMixin';

import { capitalize } from 'qs_vuetify/src/plugins/filters';
import i18n from 'qs_vuetify/src/plugins/i18n';

const DuplicatesDialog = () => import('@/views/Duplicates/DuplicatesDialog.vue');
const Duplicates = () => import('@/views/Duplicates/Duplicates.vue');

const duplicateRoutes = [
  {
    path: '/duplicates',
    name: 'Duplicates',
    component: Duplicates,
    meta: {
      data: {
        duplicates: {
          index: {},
        },
        filters: {
          index: {
            params: {
              repository: 'Duplicate',
              fields: '*',
            },
          },
        },
      },
      title: capitalize(i18n.tc('models.duplicates.name', 2)),
      menus: ['moderation'],
      requires: ['DUPLICATES_RETRIEVE'],
    },
    children: [
      {
        path: ':id',
        name: 'DuplicatesDialog',
        component: DuplicatesDialog,
        props: true,
        meta: {
          data: {
            duplicates: {
              loadEmpty: {
                conditional(vm: Vue) {
                  return vm.$route.params.id === 'new';
                },
              },
              index: {
                conditional(vm: Vue) {
                  return !vm.$store.state.duplicates.lastLoadedAt;
                },
                contextual(vm: Vue) {
                  const { options, params } = vm.$store.state.duplicatesView;
                  return {
                    ...ListMixin.buildListState(options, params),
                  };
                },
              },
              retrieve: {
                conditional(vm: Vue) {
                  return vm.$route.params.id !== 'new';
                },
                contextual(vm: Vue) {
                  return {
                    id: vm.$route.params.id,
                  };
                },
              },
            },
          },
          title: capitalize(i18n.tc('models.duplicates.name', 1)),
          menus: [],
          requires: ['DUPLICATES_RETRIEVE'],
        },
      },
    ],
  },
];

export default duplicateRoutes;
