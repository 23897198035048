


















































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { Model, Persisted } from 'qs_vuetify/src/types/models';

@Component
export default class ItemNavigation<M extends Persisted<Model>> extends Vue {
  @Prop({ required: false, type: Function, default: undefined }) callback?: any;
  @Prop({ required: true, type: String }) modelName!: string;
  @Prop({ required: false, type: Object, default: undefined }) item?: M;
  @Prop({ required: true, type: Array }) items!: M[];

  get canLoadNext(): boolean {
    return !this.next && !!this.callback;
  }

  get index(): number | null {
    if (!this.item) {
      return null;
    }

    const index = this.items.findIndex((i) => i.id === this.item?.id);

    if (index === -1) {
      return null;
    }

    return index;
  }

  get next(): M | null {
    if (!this.item) {
      return null;
    }

    if (this.index === null) {
      return null;
    }

    if (this.index + 1 > this.items.length) {
      return null;
    }

    return this.items[this.index + 1];
  }

  get prev(): M | null {
    if (!this.item) {
      return null;
    }

    if (this.index === null) {
      return null;
    }

    if (this.index - 1 < 0) {
      return null;
    }

    return this.items[this.index - 1];
  }

  get total(): number {
    return this.items.length;
  }
}
