












































































































































































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';

import QsConfirmationModal from 'qs_vuetify/src/components/Dialog/QsConfirmationModal.vue';
import QsContactCard from 'qs_vuetify/src/components/Contacts/QsContactCard.vue';
import QsDateField from 'qs_vuetify/src/components/Fields/QsDateField.vue';
import QsExportMenu from 'qs_vuetify/src/components/QsExportMenu.vue';
import QsFilters from 'qs_vuetify/src/components/QsFilters.vue';
import QsList from 'qs_vuetify/src/components/QsList.vue';
import QsNewDuplicateModal
  from 'qs_vuetify/src/components/Dialog/Contacts/QsNewDuplicateModal.vue';
import QsTextField from 'qs_vuetify/src/components/Fields/QsTextField.vue';
import QsSplitButton from 'qs_vuetify/src/components/Buttons/QsSplitButton.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import ContactsListActions from 'qs_vuetify/src/mixins/models/ContactsListActions';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import ExportMixin from 'qs_vuetify/src/mixins/ExportMixin';
import LazyListMixin from 'qs_vuetify/src/mixins/LazyListMixin';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';

import { ListAction } from 'qs_vuetify/src/types/components';
import { Contact, Duplicate, PersistedContact } from 'qs_vuetify/src/types/models';
import { FiltersDefinition, RestParams } from 'qs_vuetify/src/types/states';
import { TransactionContactStatus } from '@/types/states';

const contacts: any = namespace('contacts');
const duplicates: any = namespace('duplicates');
const view: any = namespace('contactsView');

@Component({
  components: {
    QsConfirmationModal,
    QsContactCard,
    QsDateField,
    QsExportMenu,
    QsFilters,
    QsList,
    QsNewDuplicateModal,
    QsTextField,
    QsSplitButton,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class ContactList extends mixins(
  AuthenticationMixin,
  ContactsListActions,
  DataRouteGuards,
  ExportMixin,
  LazyListMixin,
  ListMixin,
) {
  @contacts.Getter data!: Array<Contact>;
  @contacts.Getter exportUrl!: string;
  @contacts.Getter filtersDefinition!: FiltersDefinition;
  @contacts.Getter loading!: boolean;
  @contacts.Getter total!: number;
  @contacts.Getter selected !: Array<Contact>;
  @contacts.Getter slug!: string;
  @contacts.Mutation('selected') setSelected!: any;

  @duplicates.Getter('item') duplicate!: Duplicate;
  @duplicates.Mutation('item') setDuplicate!: any;

  @view.Getter params!: RestParams;
  @view.Getter selectedItems!: Array<PersistedContact>;
  @view.Mutation addSelectedItem!: any;
  @view.Mutation removeSelectedItem!: any;
  @view.Mutation setParams!: any;
  @view.Mutation setSelectedItems!: any;

  filtersLoaded = true;

  setContactsParam = this.buildSetParam('contacts', (params) => {
    this.setParams({
      ...params,
      page: 1,
    });
  });

  get actionParams(): RestParams {
    return this.params;
  }

  get exportParams(): RestParams {
    return this.actionParams;
  }

  mounted() {
    this.setAction();

    this.$store.commit('contacts/exportFields', [
      'id',
      'status',
      'first_name',
      'last_name',
      'v1_contact_id',
      'district_name',
      'gender',
      'birthdate',
      'address',
      'apartment',
      'city',
      'postal_code',
      'home_phone',
      'email',
      'first_membership_period_start',
      'last_membership_period_start',
    ]);

    if (this.data.length === 0) {
      this.loadFirstPage();
    }
  }

  get listActions(): ListAction[] {
    const n = this.selectedItems.length;

    return [
      {
        callback: () => { this.newDuplicateAction.value = true; },
        disabled: !this.canCreateDuplicate,
        icon: 'mdi-account-supervisor',
        label: this.$tc('models.contacts.actions.new_duplicate', n - 1),
      },
      {
        callback: () => {
          this.confirmAndFixMemberships();
        },
        disabled: n < 0,
        icon: 'mdi-credit-card-refresh',
        label: 'Réorganiser les adhésions',
      },
      {
        callback: () => {
          this.confirmAndUpdateInstanceContactsJoinTable();
        },
        disabled: n < 0,
        icon: 'mdi-crosshairs',
        label: 'Forcer le recalcul des instances',
      },
      {
        callback: () => {
          this.confirmAndUpdateAdministrativeEntities();
        },
        disabled: n < 0,
        icon: 'mdi-flag',
        label: 'Forcer le recalcul des entités administratives',
      },
      {
        callback: () => {
          const forType = n === 0 ? 'new' : 'existing';
          this.goToNewTransaction(forType);
        },
        disabled: n > 1,
        icon: 'mdi-credit-card-plus',
        label: this.$tc('models.contacts.actions.new_transaction', n),
      },
    ];
  }

  get showAlerts() {
    return this.$store.state.contactsView.showAlerts;
  }

  set showAlerts(val: boolean) {
    this.$store.commit('contactsView/showAlerts', val);
  }

  get showExtraInformation() {
    return this.$store.state.contactsView.showExtraInformation;
  }

  set showExtraInformation(val: boolean) {
    this.$store.commit('contactsView/showExtraInformation', val);
  }

  get viewParams(): { [key: string]: RestParams } {
    return {
      contacts: {
        ...this.params,
        order: 'v1_contact_id',
      },
    };
  }

  confirmAndFixMemberships() {
    this.confirmList(
      '',
      'Êtes-vous certain·e de vouloir réorganiser les memberships des contacts sélectionnés?'
      + ' Cette action est irréversible',
      'warning',
      'mdi-alert',
      async () => {
        if (this.selectedItems.length > 0) {
          await this.$store.dispatch('contacts/fixMembershipsPeriodStart', {
            id: this.selectedItems.map((c) => c.id).join(','),
          });

          this.$store.commit('global/addNotification', {
            color: 'success',
            message: 'Succès',
          });
        }
      },
    );
  }

  confirmAndUpdateAdministrativeEntities() {
    this.confirmList(
      '',
      'Êtes-vous certain·e de vouloir forcer le recalcul des entités administratives?'
      + ' Cette action est irréversible',
      'warning',
      'mdi-alert',
      async () => {
        if (this.selectedItems.length > 0) {
          await this.$store.dispatch('contacts/updateAdministrativeEntities', {
            id: this.selectedItems.map((c) => c.id).join(','),
          });

          this.$store.commit('global/addNotification', {
            color: 'success',
            message: 'Succès',
          });
        }
      },
    );
  }

  confirmAndUpdateInstanceContactsJoinTable() {
    this.confirmList(
      '',
      'Êtes-vous certain·e de vouloir forcer le recalcul des instances pour les contacts sélectionnés?'
      + ' Cette action est irréversible',
      'warning',
      'mdi-alert',
      async () => {
        if (this.selectedItems.length > 0) {
          await this.$store.dispatch('contacts/updateInstanceContactsJoinTable', {
            id: this.selectedItems.map((c) => c.id).join(','),
          });

          this.$store.commit('global/addNotification', {
            color: 'success',
            message: 'Succès',
          });
        }
      },
    );
  }

  emptySelection() {
    this.setSelectedItems([]);
  }

  goToNewTransaction(transactionContactStatus: TransactionContactStatus) {
    this.$store.commit('transactionsView/setTransactionContactStatus', transactionContactStatus);

    if (this.selectedItems.length === 1) {
      this.$store.commit('transactionsView/setUseSelected', true);
    }

    this.$router.push({ name: 'NewTransactionsForm' });
  }

  isSelected(item: PersistedContact): boolean {
    return !!this.selectedItems.find((i) => i.id === item.id);
  }

  async saveDuplicateAndRedirectToModeration() {
    if (!this.newDuplicateAction.target?.id) {
      return;
    }

    const {
      target: {
        id: targetId,
      },
    } = this.newDuplicateAction;

    try {
      await this.saveDuplicate();

      this.$store.commit('duplicatesView/setParams', {
        'original_contact.id': targetId,
      });

      this.$router.push('/duplicates');
    } catch (e) {
      // Stay
    }
  }

  selectAll(select: boolean) {
    if (select) {
      this.setSelectedItems(this.data);
    } else {
      this.emptySelection();
    }
  }

  setAction() {
    if (this.userHas('CONTACTS_CREATE')) {
      this.$store.commit('global/actions', [
        {
          onClick: () => {
            this.$router.push('/contacts/new');
          },
          color: 'primary',
          icon: 'mdi-plus',
        },
      ]);
    }
  }

  updateSelection(state: boolean, item: PersistedContact) {
    if (state) {
      this.addSelectedItem(item);
    } else {
      this.removeSelectedItem(item);
    }
  }
}
