

















































































































































































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Prop, Watch } from 'vue-property-decorator';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import FormMixin from 'qs_vuetify/src/mixins/FormMixin';

import QsActionModal from 'qs_vuetify/src/components/Dialog/QsActionModal.vue';
import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';
import QsConfirmationModal from 'qs_vuetify/src/components/Dialog/QsConfirmationModal.vue';
import QsContactEventsSection from 'qs_vuetify/src/components/Contacts/QsContactEventsSection.vue';
import QsContactFormSection from 'qs_vuetify/src/components/Contacts/QsContactFormSection.vue';
import QsContactHistory from 'qs_vuetify/src/components/Contacts/QsContactHistory.vue';
import QsContactInstances from '@/components/contacts/QsContactInstancesSection.vue';
import QsContactStatusSection from 'qs_vuetify/src/components/Contacts/QsContactStatusSection.vue';
import QsContactTagsSection from 'qs_vuetify/src/components/Contacts/QsContactTagsSection.vue';
import QsSplitButton from 'qs_vuetify/src/components/Buttons/QsSplitButton.vue';

import { PersistedContact } from 'qs_vuetify/src/types/models';
import { ActionLinkResponse, ErrorResponse } from 'qs_vuetify/src/types/responses';
import { RestParams } from 'qs_vuetify/src/types/states';
import { AppNotification, DataTableOptions } from 'qs_vuetify/src/types/components';

import axios from 'qs_vuetify/src/plugins/axios';
import { PDFDocument } from 'pdf-lib';

const contacts: any = namespace('contacts');
const global: any = namespace('global');
const users: any = namespace('users');
const view: any = namespace('contactsView');

@Component({
  components: {
    QsActionModal,
    QsButton,
    QsConfirmationModal,
    QsContactEventsSection,
    QsContactFormSection,
    QsContactHistory,
    QsContactInstances,
    QsContactStatusSection,
    QsContactTagsSection,
    QsSplitButton,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class ContactsForm extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  FormMixin,
) {
  @global.Mutation addNotification!: (arg: AppNotification) => void;

  @contacts.Getter data!: PersistedContact[];
  @contacts.Getter error!: ErrorResponse;
  @contacts.Getter item!: PersistedContact;
  @contacts.Getter loading!: boolean;
  @contacts.Getter slug!: string;
  @contacts.Getter total!: number;
  @contacts.Mutation('item') syncItem!: any

  @users.Getter actionLinkResponse!: ActionLinkResponse | null;

  @view.Getter commentsModal!: boolean;
  @view.Getter emailsModal !: boolean;
  @view.Getter contactExchangeModal!: boolean;
  @view.Getter options!: DataTableOptions;
  @view.Getter params!: RestParams;
  @view.Getter tagsModal!: boolean;
  @view.Mutation setCommentsModal!: any;
  @view.Mutation setContactExchangeModal: any;
  @view.Mutation setEmailsModal!: any;
  @view.Mutation('options') setOptions!: any;
  @view.Mutation('params') setParams!: any;

  @Prop({ required: true }) id!: number | string;

  loginLinkLoading = false;

  // eslint-disable-next-line class-methods-use-this
  get viewParams(): Record<string, RestParams> {
    return {
      contacts: {
        with_excluded: true,
        fields: [
          'active_withdrawal.id',
          'user.email',
          'status',
          'withdrawn',
          'v1_contact_id',
        ].join(','),
      },
    };
  }

  withdrawalComment: string = '';
  withdrawalLoading: boolean = false;
  withdrawalModal: boolean = false;

  // eslint-disable-next-line class-methods-use-this, consistent-return
  async downloadContributionForm() {
    const url = '/QS-FC11-07-23--web.pdf';

    if (!url) {
      return false;
    }

    const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const form = pdfDoc.getForm();

    const fields = [
      { itemKey: 'first_name', formKey: 'Prénom' },
      { itemKey: 'last_name', formKey: 'Nom à la naissance' },
      { itemKey: 'address', formKey: 'Adresse' },
      { itemKey: 'apartement', formKey: 'Appartement' },
      { itemKey: 'city', formKey: 'Ville' },
      { itemKey: 'postal_code', formKey: 'Code postal' },
      { itemKey: 'home_phone', formKey: 'Téléphone' },
      { itemKey: 'email', formKey: 'Courriel' },
      { itemKey: 'birthdate', formKey: 'Date de naissance' },
    ];

    const { item } = (this.$refs.QsContactFormSection as any);

    fields.forEach((field) => {
      form.getTextField(field.formKey).setText(item[field.itemKey] || '');
    });

    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], {
      type: 'application/pdf',
    });
    const href = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = href;
    a.download = `QS-FC11-07-23--web | ${item.last_name}, ${item.first_name} (#${item.v1_contact_id})`;
    document.body.appendChild(a);
    // a.style = 'display: none';
    a.click();
    a.remove();

    setTimeout(() => window.URL.revokeObjectURL(href), 1000);
  }

  async getLoginLink() {
    this.loginLinkLoading = true;

    try {
      await this.$store.dispatch('users/generateActionLink', {
        id: this.item.user.id,
        action: 'login',
      });
    } catch (e) {
      this.addNotification({
        color: 'error',
        message: 'Erreur serveur lors de la création du lien de connexion.',
        timeout: 2500,
      });
    }

    const actionLinkResponseUrl = this.actionLinkResponse?.link;
    if (actionLinkResponseUrl) {
      navigator.clipboard.writeText(actionLinkResponseUrl);

      this.addNotification({
        color: 'success',
        message: 'Le lien de connexion a été copié dans votre presse-papiers.',
        action: () => {
          window.open(actionLinkResponseUrl, '');
        },
        timeout: 2500,
      });

      this.$store.commit('users/actionLinkResponse', null);
    } else {
      this.addNotification({
        color: 'error',
        message: 'Erreur lors de la création du lien de connexion.',
        timeout: 2500,
      });
    }

    this.loginLinkLoading = false;
  }

  async sendLoginLink() {
    this.loginLinkLoading = true;

    try {
      await this.$store.dispatch('users/sendActionLink', {
        id: this.item.user.id,
        action: 'login',
      });
    } catch (e) {
      this.addNotification({
        color: 'error',
        message: "Erreur serveur lors de l'envoi du lien de connexion.",
        timeout: 2500,
      });
    }

    this.addNotification({
      color: 'success',
      message: 'Le lien de connexion a été envoyé par courriel.',
      timeout: 2500,
    });

    this.loginLinkLoading = false;
  }

  setGlobalSubtitle() {
    if (!this.itemReady) {
      this.$store.commit('global/subtitle', 'Chargement...');
    } else {
      this.$store.commit('global/subtitle', this.item?.full_name);
    }
    this.$emit('updateHead');
  }

  async addWithdrawal(comment: string) {
    this.withdrawalLoading = true;
    try {
      await axios.post(`/contacts/${this.id}/withdrawals`, {
        comment,
        start_at: this.$dayjs().format('YYYY-MM-DD'),
      });

      this.addNotification({
        color: 'success',
        message: 'Le contact a été retiré',
        timeout: 2500,
      });
    } catch (e) {
      // bs
      this.$emit('error', e);
      this.addNotification({
        color: 'error',
        message: 'Une erreur est survenue lors du retrait',
        timeout: 2500,
      });
    } finally {
      const section: any = this.$refs.QsContactStatusSection;
      await section.getItem();
      this.withdrawalLoading = false;
      this.withdrawalModal = false;
    }
  }

  async endWithdrawal() {
    if (!this.item.active_withdrawal) {
      return;
    }

    this.withdrawalLoading = true;
    try {
      await axios.put(`/contacts/${this.id}/withdrawals/${this.item.active_withdrawal.id}`, {
        end_at: this.$dayjs().format('YYYY-MM-DD'),
      });

      this.addNotification({
        color: 'success',
        message: 'Le retrait a été terminé',
        timeout: 2500,
      });
    } catch (e) {
      // bs
      this.$emit('error', e);
      this.addNotification({
        color: 'error',
        message: 'Une erreur est survenue en terminant le retrait',
        timeout: 2500,
      });
    } finally {
      const section: any = this.$refs.QsContactStatusSection;
      this.reloadDataRoutesData();
      await section.getItem();
      this.withdrawalLoading = false;
      this.withdrawalModal = false;
    }
  }

  async confirmThenEndWithdrawal() {
    this.confirm(
      '',
      'Voulez-vous mettre fin au retrait de ce contact maintenant?',
      async () => {
        this.dialog.loading = true;
        await this.endWithdrawal();
        this.dialog.loading = false;
        this.dialog.value = false;
        this.reloadDataRoutesData();
      },
      'warning',
      'mdi-alert',
    );
  }

  async setDeceased() {
    if (!this.id) {
      return;
    }

    try {
      await axios.put(`/contacts/${this.id}/tags/0`);

      this.addNotification({
        color: 'info',
        message: 'Le décès a été consigné',
        timeout: 2500,
      });
    } catch (e) {
      // bs
      this.$emit('error', e);
      this.addNotification({
        color: 'error',
        message: 'Erreur lors de la consignation du décès',
        timeout: 2500,
      });
    } finally {
      const section: any = this.$refs.QsContactStatusSection;
      await section.getItem();
    }
  }

  async confirmThenSetDeceased() {
    this.confirm(
      '',
      'Voulez-vous consigner le décès de cette personne?',
      async () => {
        this.dialog.loading = true;
        await this.setDeceased();
        this.dialog.loading = false;
        this.dialog.value = false;
        this.reloadDataRoutesData();
      },
      'error',
      'mdi-alert',
    );
  }

  @Watch('itemReady')
  onItemReadyChanged() {
    this.setGlobalSubtitle();
  }

  @Watch('$route', { deep: true })
  onRouteChanged() {
    this.reloadDataRoutesData();
    this.setGlobalSubtitle();
  }
}
