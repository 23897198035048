var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "admin-popover",
    { staticClass: "mr-2" },
    [
      _c("v-card-text", [
        _c(
          "div",
          { staticClass: "d-flex" },
          [
            _c("span", { staticClass: "body-1 font-weight-bold" }, [
              _vm._v(_vm._s(_vm.item.full_name))
            ]),
            _c("v-spacer"),
            _c("span", { staticClass: "pt-1 ml-12 body-2" }, [
              _vm._v(
                " " +
                  _vm._s(_vm._f("status")(_vm.item.status, "contacts")) +
                  " "
              )
            ])
          ],
          1
        ),
        _c("div", { staticClass: "caption" }, [
          _vm._v(" # " + _vm._s(_vm.item.v1_contact_id)),
          _c("br"),
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c("span", [
                _vm._v(_vm._s(_vm.item.district ? _vm.item.district.name : ""))
              ]),
              _c("v-spacer"),
              _c("span", [_vm._v(_vm._s(_vm.item.postal_code))])
            ],
            1
          )
        ]),
        _c("p", { staticClass: "body-2 pt-2" }, [
          _vm.item.birthdate
            ? _c("span", [
                _vm._v(
                  " " +
                    _vm._s(_vm._f("date")(_vm.item.birthdate)) +
                    " (" +
                    _vm._s(_vm.item.age) +
                    " ans) "
                )
              ])
            : _vm._e(),
          _c("br"),
          _vm.item.email
            ? _c("span", [_vm._v(" " + _vm._s(_vm.item.email) + " ")])
            : _vm._e(),
          _c("br"),
          _vm._v(" " + _vm._s(_vm.item.home_phone) + " ")
        ])
      ]),
      _c(
        "v-btn",
        {
          attrs: {
            block: "",
            text: "",
            tile: "",
            to: { name: "ContactsForm", params: { id: _vm.item.id } },
            small: ""
          }
        },
        [_vm._v(" Voir la fiche ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }