












































































































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';

import QsConfirmationModal from 'qs_vuetify/src/components/Dialog/QsConfirmationModal.vue';
import QsContactListItem from 'qs_vuetify/src/components/Contacts/QsContactListItem.vue';
import QsDataTable from 'qs_vuetify/src/components/QsDataTable.vue';
import QsExportMenu from 'qs_vuetify/src/components/QsExportMenu.vue';
import QsFilters from 'qs_vuetify/src/components/QsFilters.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import ExportMixin from 'qs_vuetify/src/mixins/ExportMixin';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';

import { DataTableOptions } from 'qs_vuetify/src/types/components';
import { Model, PersistedTransaction, Transaction } from 'qs_vuetify/src/types/models';
import { FiltersDefinition, RestParams } from 'qs_vuetify/src/types/states';

import ContactAdminPopover from '@/components/ContactAdminPopover.vue';
import QsStatsCard from '@/components/QsStatsCard.vue';

import InteractsWithNavbar from '@/mixins/InteractsWithNavbar';

const global: any = namespace('global');
const store: any = namespace('transactions');
const view: any = namespace('transactionsView');

@Component({
  components: {
    ContactAdminPopover,
    QsConfirmationModal,
    QsContactListItem,
    QsDataTable,
    QsExportMenu,
    QsFilters,
    QsStatsCard,
  },
  filters: {
    statusColor(status: string) {
      switch (status) {
        case 'canceled':
        case 'failed':
          return 'error';
        case 'refunded':
        case 'pending':
          return 'warning';
        case 'success':
          return 'success';

        default:
          return 'info';
      }
    },
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
class Transactions
  extends mixins(
    AuthenticationMixin,
    DataRouteGuards,
    ExportMixin,
    InteractsWithNavbar,
    ListMixin,
  ) {
  @global.Mutation addNotification!: Function;
  @global.Mutation removeNotification!: Function;

  @store.Getter data!: Array<Transaction>;
  @store.Getter exportUrl!: string;
  @store.Getter filtersDefinition!: FiltersDefinition;
  @store.Getter loading!: boolean;
  @store.Getter total!: number;
  @store.Getter slug!: string;
  @store.Getter stats!: object;

  @view.Getter options!: DataTableOptions;
  @view.Getter params!: RestParams;
  @view.Mutation('options') setOptions!: any;
  @view.Mutation('params') setViewParams!: any;

  setTransactionsParam = this.buildSetParam('transactions', this.setParams);

  headers = [
    { text: 'ID', value: 'id' },
    { text: 'Statut', value: 'status', sortable: false },
    { text: 'Mode de paiement (ID externe)', value: 'type' },
    { text: 'Type', value: 'external_type' },
    { text: 'Contact', value: 'contact.full_name', sortable: false },
    { text: 'Numéro de membre', value: 'v1_contact_id' },
    { text: 'Date', value: 'executed_at', align: 'right' },
    { text: 'Montant', value: 'amount_in_cad', align: 'right' },
    { text: '', value: 'actions' },
  ];

  get actionParams(): RestParams {
    return this.params;
  }

  get exportParams(): RestParams {
    return this.actionParams;
  }

  get viewParams(): { [key: string]: RestParams } {
    return {
      transactions: {
        ...ListMixin.buildListState(this.options, this.params),
      },
    };
  }

  mounted() {
    this.setGlobalActions();

    this.$store.commit('transactions/exportFields', [
      'id',
      'amount_in_cad',
      'external_id',
      'executed_at',
      'contact_full_name',
      'contact_email',
      'v1_contact_id',
      'type',
      'external_type',
      'status',
    ]);
  }

  confirmThenDeleteTransaction(item: PersistedTransaction) {
    this.confirmList(
      '',
      'La suppression de la transaction est <strong>irréversible</strong>.'
        + '<br>Voulez-vous continuer?',
      'error',
      'mdi-alert',
      async () => {
        try {
          await this.$store.dispatch('transactions/destroy', { id: item.id });
          this.addNotification({
            color: 'success',
            message: 'Suppression confirmée',
          });
          this.reloadDataRoutesData(['transactions']);
        } catch (e) {
          this.addNotification({
            color: 'error',
            message: 'Erreur lors de la suppression',
          });
        }
      },
    );
  }

  reloadRoutesData() {
    this.reloadDataRoutesData(['transactions']);
  }

  setGlobalActions() {
    if (this.userHas('TRANSACTIONS_CREATE')) {
      this.$store.commit('global/actions', [
        {
          onClick: () => {
            this.$router.push('/transactions/new');
          },
          color: 'primary',
          icon: 'mdi-plus',
        },
      ]);
    }
  }

  setParams(params: RestParams) {
    this.setOptions({
      ...this.options,
      page: 1,
    });
    this.setViewParams(params);
  }
}

interface Transactions extends AuthenticationMixin, DataRouteGuards, ListMixin<Model> {}

export default Transactions;
