



























































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';

import QsBaseLayout from 'qs_vuetify/src/components/Layout/QsBaseLayout.vue';
import QsConfirmationModal from 'qs_vuetify/src/components/Dialog/QsConfirmationModal.vue';
import QsDataTable from 'qs_vuetify/src/components/QsDataTable.vue';
import QsFilters from 'qs_vuetify/src/components/QsFilters.vue';
// import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';
// import QsRelationField from 'qs_vuetify/src/components/Fields/QsRelationField.vue';

import { DataTableOptions } from 'qs_vuetify/src/types/components';
import { PersistedSmsCampaign } from 'qs_vuetify/src/types/models';
import { FiltersDefinition, RestParams } from 'qs_vuetify/src/types/states';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';
import InteractsWithNavbar from '@/mixins/InteractsWithNavbar';

const global: any = namespace('global');
const sent_sms = namespace('sent_sms');
const sms_campaigns = namespace('sms_campaigns');
const view: any = namespace('automatedEmailTemplatesView');

@Component({
  components: {
    QsBaseLayout,
    QsConfirmationModal,
    QsDataTable,
    QsFilters,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class SmsCampaigns extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  InteractsWithNavbar,
  ListMixin,
) {
  @global.Mutation addNotification!: Function;

  @sms_campaigns.Getter data!: PersistedSmsCampaign[];
  @sms_campaigns.Getter exportUrl!: string;
  @sms_campaigns.Getter filtersDefinition!: FiltersDefinition;
  @sms_campaigns.Getter loading!: boolean;
  @sms_campaigns.Getter total!: number;
  @sms_campaigns.Getter slug!: string;

  @sms_campaigns.Action send!: (payload: { id: number; amount?: number }) => void;

  @sent_sms.Getter queuedTotal!: number;

  @view.Getter options!: DataTableOptions;
  @view.Getter params!: RestParams;
  @view.Getter selectedItems!: PersistedSmsCampaign[];
  @view.Mutation setOptions!: any;
  @view.Mutation setParams!: any;
  @view.Mutation addSelectedItem!: any;
  @view.Mutation removeSelectedItem!: any;
  @view.Mutation setSelectedItems!: any;

  setListParams = this.buildSetParam('automated_email_templates', this.setParams);

  headers = [
    { text: 'Id', value: 'id', sortable: true },
    { text: 'Nom', value: 'name', sortable: true },
    { text: 'Instance', value: 'instance.name', sortable: true },
    { text: 'Contacs / Envoyés / Restants', value: 'stats', sortable: false },
    { text: 'Actions', value: 'actions', sortable: false },
  ];

  get viewParams() {
    return {
      sms_campaigns: {
        ...ListMixin.buildListState(this.options, this.params),
      },
    };
  }

  mounted() {
    this.setGlobalActions();
  }

  setGlobalActions() {
    if (this.userHas('SMS_CAMPAIGNS_CREATE')) {
      this.commitGlobalActions([
        {
          onClick: () => {
            this.$router.push('/sms/new');
          },
          color: 'primary',
          icon: 'mdi-plus',
          tooltip: 'Nouvelle campagne',
        },
      ]);
    }
  }

  setGlobalSubtitle() {
    this.$store.commit('global/title', 'SMS');
    this.$store.commit('global/subtitle', 'Liste des campagnes');
    this.$emit('updateHead');
  }
}
