import Cookies from 'js-cookie';
import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import VuexPersistence from 'vuex-persist';
import axios from 'axios';

import { RootState } from 'qs_vuetify/src/types/states';
import {
  AutomatedEmailTemplate,
  Campaign,
  Comment,
  Contact,
  ContactComment,
  ContactExchange,
  Contribution,
  Duplicate,
  Email,
  Event,
  Filter,
  FormDefinitionTemplate,
  Importable,
  Instance,
  Membership,
  // Model,
  Phone,
  Revision,
  SentEmail,
  SmsCampaign,
  Source,
  Tag,
  Withdrawal,
} from 'qs_vuetify/src/types/models';
import { ContributionType, SentSms } from '@/types/models';

import AuthModule from 'qs_vuetify/src/store/auth/index';
import ComponentModule from 'qs_vuetify/src/store/components/index';
import GlobalViewModule from 'qs_vuetify/src/store/views/global';
import buildStoreModule from 'qs_vuetify/src/store/buildStoreModule';
import buildViewsStoreModule from 'qs_vuetify/src/store/views/buildViewsStoreModule';

import { automatedEmailTemplates } from './automatedEmailTemplates';
import { contacts } from './contacts';
import { districts } from './districts';
import { imports } from './imports';
import { sms } from './sms';
import { transactions } from './transactions';
import { users } from './users';

import views from './views';

import contactsView from './views/contactsView';
import emailsView from './views/emailsView';
import importablesViews from './views/importablesViews';
import instancesViews from './views/instancesViews';
import membershipsViews from './views/membershipsViews';
import sentEmailsViews from './views/sentEmailsViews';
import tagsView from './views/tagsView';
import transactionsView from './views/transactionsView';

const { importsViewState: importsView } = views;

Vue.use(Vuex);

const vuexLocal = new VuexPersistence<RootState>({
  key: 'administration',
  storage: window.localStorage,
  reducer(state) {
    if (!state.auth || !state.auth?.user) {
      return {};
    }

    return {
      auth: {
        user: state.auth?.user,
      },
      automatedEmailTemplatesView: state.automatedEmailTemplatesView,
      contactsView: state.contactsView,
      contributionTypesViews: state.contributionTypesViews,
      contributionsView: state.contributionsView,
      duplicatesView: state.duplicatesView,
      emailsView: state.emailsView,
      eventsViews: state.eventsView,
      formDefinitionTemplatesViews: state.FormDefinitionTemplatesView,
      global: {
        ...state.global,
        notifications: [],
        previousLocation: null,
      },
      importsView: state.importsView,
      instancesViews: state.instancesView,
      membershipsView: state.membershipsView,
      phonesViews: state.phonesViews,
      postalCodesViews: state.postalCodesViews,
      sentEmailsViews: state.sentEmailsViews,
      tagsView: state.tagsView,
      transactionsView: state.transactionsView,
    };
  },
});

const vuexCookie = new VuexPersistence<RootState>({
  key: 'qs_auth_v2',
  restoreState: (key) => Cookies.getJSON(key),
  saveState: async (key, state) => {
    await Cookies.set(key, state, {
      expires: 3,
      secure: process.env.NODE_ENV !== 'development',
      domain: process.env.VUE_APP_COOKIE_DOMAIN,
      path: '/',
      sameSite: 'Lax',
    });
  },
  reducer(state) {
    return {
      auth: {
        token: state.auth?.token,
        refreshToken: state.auth?.refreshToken,
      },
    };
  },
});

const storeOptions: StoreOptions<RootState> = {
  state: {
    key: 'administration',
    theme: 'labase',
    version: '1.0.0',
  },
  modules: {
    auth: AuthModule,
    automated_email_templates: automatedEmailTemplates,
    automatedEmailTemplatesView: buildViewsStoreModule<AutomatedEmailTemplate>(),
    global: GlobalViewModule,
    campaigns: buildStoreModule<Campaign>('campaigns'),
    comments: buildStoreModule<Comment>('comments'),
    components: ComponentModule,
    contacts,
    contact_comments: buildStoreModule<ContactComment>('contact_comments'),
    contact_exchanges: buildStoreModule<ContactExchange>('contact_exchanges'),
    contactsView,
    contributions: buildStoreModule<Contribution>('contributions'),
    contributionsView: buildViewsStoreModule<ContributionType>({
      fields: [
        'affected_to_instance',
        'amount_in_cad',
        'contact_full_name',
        'contact_v1_contact_id',
        'contact.age',
        'contact.birthdate',
        'contact.district.name',
        'contact.email',
        'contact.full_name',
        'contact.home_phone',
        'contact.postal_code',
        'contact.status',
        'contact.v1_contact_id',
        'dgeq_instance_name',
        'deposit_date',
        'district_name',
        'instance_id',
        'instance.name',
        'transaction_id',
        'transaction_id',
        'transaction.executed_at',
        'transaction.external_id',
        'transaction.status',
        'transaction.type',
      ].join(','),
    }, {
      sortBy: ['transaction_id'],
      sortDesc: [true],
      page: 1,
      itemsPerPage: 20,
    }),
    contribution_types: buildStoreModule<ContributionType>('contribution_types'),
    contributionTypesViews: buildViewsStoreModule<ContributionType>({ fields: '*' }),
    districts,
    duplicates: buildStoreModule<Duplicate>('duplicates', ['merge']),
    duplicatesView: buildViewsStoreModule<Duplicate>({
      unmoderated: true,
    }, {
      sortBy: ['created_at'],
      sortDesc: [true],
      page: 1,
      itemsPerPage: 5,
    }),
    emails: buildStoreModule<Email>('emails'),
    emailsView,
    filters: buildStoreModule<Filter>('filters'),
    form_definition_templates: buildStoreModule<FormDefinitionTemplate>('form_definition_templates'),
    formDefinitionTemplatesViews: buildViewsStoreModule<FormDefinitionTemplate>({
      fields: [
        'description',
        'form_definition',
        'instance.name',
        'name',
      ].join(','),
    }),
    imports,
    importsView,
    events: buildStoreModule<Event>('events'),
    eventsViews: buildViewsStoreModule<Event>({}, {
      sortBy: ['id'],
      sortDesc: [true],
      page: 1,
      itemsPerPage: 20,
    }),
    importables: buildStoreModule<Importable>('importables'),
    importablesViews,
    instances: buildStoreModule<Instance>('instances'),
    instancesViews,
    instance_types: buildStoreModule('instance_types'),
    mailbox_options: buildStoreModule<Contact>('contacts'),
    mailboxOptionViews: buildViewsStoreModule<Contact>(),
    memberships: buildStoreModule<Membership>('memberships'),
    membershipsViews,
    phones: buildStoreModule<Phone>('phones'),
    phonesViews: buildViewsStoreModule<Phone>({
      fields: 'contacts.id,oqlf_format,status,type',
    }),
    postal_codes: buildStoreModule('postal_codes'),
    postalCodesViews: buildViewsStoreModule<ContributionType>({
      fields: '*,districts.name',
    }),
    regions: buildStoreModule('regions'),
    revisions: buildStoreModule<Revision>('revisions'),
    revisionsViews: buildViewsStoreModule<Revision>(),
    sent_emails: buildStoreModule<SentEmail>('sent_emails'),
    sent_sms: sms,
    sentSmsViews: buildViewsStoreModule<SentSms>({}, {
      sortBy: ['unread_replies_count'],
      sortDesc: [true],
      page: 1,
      itemsPerPage: 50,
    }),
    sms_campaigns: buildStoreModule<SmsCampaign>('sms_campaigns'),
    smsCampaignsViews: buildViewsStoreModule<SmsCampaign>(),
    sentEmailsViews,
    sources: buildStoreModule<Source>('sources'),
    tags: buildStoreModule<Tag>('tags'),
    tagsView,
    transactions,
    transactionsView,
    users,
    withdrawals: buildStoreModule<Withdrawal>('withdrawals'),
    withdrawalsViews: buildViewsStoreModule<Withdrawal>({ prefix: 'contacts' }),
  },
  plugins: [vuexLocal.plugin, vuexCookie.plugin],
};

const store = new Vuex.Store(storeOptions);

axios.interceptors.request.use((config) => {
  if (store.state.auth && store.state.auth.token) {
    // eslint-disable-next-line
    config.headers.common.Authorization = `Bearer ${store.state.auth.token}`;
  }
  return config;
});

export default store;
