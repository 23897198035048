var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        [
          _c(
            "v-btn",
            {
              staticClass: "mr-4",
              attrs: {
                color: "primary",
                icon: "",
                link: "",
                tile: "",
                to: "/" + _vm.modelName
              }
            },
            [_c("v-icon", { attrs: { large: "" } }, [_vm._v("mdi-menu-left")])],
            1
          ),
          _c("span", { staticClass: "text-h6" }, [_vm._v("Retour à la liste")])
        ],
        1
      ),
      _vm.total > 0
        ? _c(
            "v-col",
            { staticClass: "text-right" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-2",
                  attrs: {
                    color: "primary",
                    disabled: !_vm.prev,
                    icon: "",
                    link: "",
                    tile: "",
                    to:
                      "/" + _vm.modelName + "/" + (_vm.prev ? _vm.prev.id : "")
                  }
                },
                [
                  _c("v-icon", { attrs: { large: "" } }, [
                    _vm._v("mdi-menu-left")
                  ])
                ],
                1
              ),
              _vm._v(
                " " + _vm._s(_vm.index + 1) + " / " + _vm._s(_vm.total) + " "
              ),
              _vm.canLoadNext
                ? _c(
                    "v-btn",
                    {
                      staticClass: "ml-2",
                      attrs: { color: "primary", icon: "", link: "", tile: "" },
                      on: {
                        click: function($event) {
                          return _vm.callback()
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { large: "" } }, [
                        _vm._v("mdi-menu-right")
                      ])
                    ],
                    1
                  )
                : _c(
                    "v-btn",
                    {
                      staticClass: "ml-2",
                      attrs: {
                        color: "primary",
                        disabled: !_vm.next,
                        icon: "",
                        link: "",
                        tile: "",
                        to:
                          "/" +
                          _vm.modelName +
                          "/" +
                          (_vm.next ? _vm.next.id : "")
                      }
                    },
                    [
                      _c("v-icon", { attrs: { large: "" } }, [
                        _vm._v("mdi-menu-right")
                      ])
                    ],
                    1
                  )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }